<template>
  <div>
    <div class="inline-flex mb-4">
      <CSegmentControl v-model="priceModel" @change="emitChange">
        <CSegmentControlItem value="unit" :label="$t('priceModels.unit')" />
        <CSegmentControlItem value="tier" :label="$t('priceModels.tier')" />
      </CSegmentControl>
    </div>
    <CTextField
      v-model="basePrice"
      :mask="priceMask"
      :label="$t('basePrice')"
      :message="$t('basePriceHelpMessage')"
      icon="currency-euro"
      inputmode="decimal"
    />
    <form v-if="tierPriceMode" v-bind="{ ...$attrs }" @submit.prevent="onSubmit">
      <div
        v-for="(price, index) in tierPrices"
        :key="index"
        class="flex items-start"
      >
        <div class="flex-grow mr-4">
          <CTextField
            v-model="price.pricePerCubicMeter"
            :id="`pricefield_${index}`"
            :mask="priceMask"
            :label="index === 0 ? $t('nettoPricePerCubicMeter') : null"
            icon="currency-euro"
            inputmode="decimal"
            required
            @mask-ready="focusNewestField"
            @blur="onTierPriceChange(index)"
            @keydown.enter.prevent="onPriceEnter(index)"
          />
        </div>
        <div class="flex items-start flex-shrink-0 w-2/5 md:w-1/3">
          <div class="flex-grow">
            <CTextField
              v-model="price.minSize"
              :disabled="index === 0"
              :mask="containerSizeRules(index).mask"
              :custom-error="minSizeErrorIndex.includes(index) ? maxSizeError : null"
              :label="index === 0 ? $t('merchantWastes.priceValidFrom') : null"
              inputmode="decimal"
              required
              @checkSizeValidity="checkSizeValidity(index, price.minSize)"
              @blur="onTierPriceChange(index)"
              @keydown.enter.prevent="onPriceEnter(index)"
            />
          </div>
          <CButton
            v-if="index > 0"
            class="ml-3"
            native-type="button"
            icon="trash-outline"
            size="icon"
            tabindex="-1"
            @click="removePriceFromTierPrices(index)"
          />
        </div>
      </div>
      <CButton
        class="mb-4"
        :disabled="addTierPriceDisabled"
        native-type="button"
        @click="addTierPriceWithFocus"
      >
        {{ $t('priceModels.addTier') }}
      </CButton>
    </form>
    <form v-else v-bind="{ ...$attrs }" @submit.prevent="onSubmit">
      <CTextField
        v-model="unitPriceModel.pricePerCubicMeter"
        :mask="priceMask"
        :label="$t('nettoPricePerCubicMeter')"
        icon="currency-euro"
        required
      />
    </form>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { MASK_CONTAINER_SIZE_FUNC, MASK_CURRENCY } from '@/constants/ui';
import germanDecimalParser, { germanDecimalToNumber } from '@/utils/germanDecimalParser';

export default {
  inheritAttrs: false,

  data: () => ({
    minSizeErrorIndex: [],
    autofocusNextField: false,
    tierPrices: [],
  }),

  computed: {
    ...mapGetters('merchantWasteForm', [
      'tierPriceMode',
      // 'currentTierIndex',
      'unitPriceModel',
      'tierPricesModel',
      'wasteMaxSize',
    ]),
    priceModel: {
      get() {
        return this.$store.state.merchantWasteForm.priceModel;
      },
      set(value) {
        return this.$store.commit('merchantWasteForm/changePriceModel', value);
      },
    },
    basePrice: {
      get() {
        return this.$store.state.merchantWasteForm.form.basePrice;
      },
      set(value) {
        return this.$store.commit('merchantWasteForm/changeBasePrice', value);
      },
    },
    priceMask() {
      return MASK_CURRENCY;
    },
    maxSizeError() {
      return this.$t('errorMessages.shouldNotBeHigherThan', { number: this.maxFromSize });
    },
    maxFromSize() {
      return germanDecimalParser(this.wasteMaxSize) - 1;
    },
    currentTierIndex() {
      if (this.tierPrices.length > 0) {
        return this.tierPrices.length - 1;
      }
      return null;
    },
    addTierPriceDisabled() {
      if (this.currentTierIndex !== null) {
        const currentPriceField = this.tierPrices[this.currentTierIndex].pricePerCubicMeter;
        const currentSizeField = this.tierPrices.slice()[this.currentTierIndex].minSize;
        const lastMinSize = germanDecimalToNumber(currentSizeField || 0);
        return !(
          (!currentPriceField && currentSizeField !== null) ||
          lastMinSize < this.maxFromSize ||
          lastMinSize === 0
        );
      }
      return false;
    },
  },

  mounted() {
    this.$set(
      this,
      'tierPrices',
      this.tierPricesModel.slice().map((tierPrice) => ({
        ...tierPrice,
        pricePerCubicMeter: tierPrice.pricePerCubicMeter
          ? germanDecimalParser(tierPrice.pricePerCubicMeter, true)
          : null,
        minSize: germanDecimalParser(tierPrice.minSize, true),
      })),
    );
  },

  methods: {
    ...mapMutations('merchantWasteForm', ['addTierPrice', 'removeTierPrice', 'changeTierPrice']),
    addTierPriceWithFocus() {
      this.autofocusNextField = true;
      this.tierPrices.push({ pricePerCubicMeter: null, minSize: 0 });
      this.addTierPrice();
    },
    focusNewestField() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.autofocusNextField) {
            this.$el.querySelector(`#pricefield_${this.currentTierIndex}`).focus();
            this.autofocusNextField = false;
          }
        }, 100);
      });
    },
    containerSizeRules(priceIndex = 0) {
      const prices = this.tierPricesModel;
      const prevPrice = priceIndex > 0 ? prices[priceIndex - 1] : null;
      const nextPrice = prices.length > priceIndex + 2 ? prices[priceIndex + 1] : null;
      const minSize = prevPrice ? Math.floor(germanDecimalParser(prevPrice.minSize) + 1) : 0;
      const maxSize = nextPrice ? Math.floor(germanDecimalParser(nextPrice.minSize) - 1) : 99;
      return {
        minSize,
        maxSize,
        mask: MASK_CONTAINER_SIZE_FUNC(minSize, maxSize, 1),
      };
    },
    onTierPriceChange(priceIndex) {
      this.changeTierPrice({
        index: priceIndex,
        data: this.tierPrices[priceIndex],
      });
    },
    removePriceFromTierPrices(priceIndex) {
      this.removeTierPrice(priceIndex);
      this.tierPrices.splice(priceIndex, 1);
    },
    checkSizeValidity(priceIndex, minSizeField) {
      if (minSizeField > this.maxFromSize) {
        this.minSizeErrorIndex.push(priceIndex);
      } else {
        const inErrorIndex = this.minSizeErrorIndex.findIndex((i) => i === priceIndex);
        if (inErrorIndex > -1) {
          this.minSizeErrorIndex.splice(inErrorIndex, 1);
        }
      }
    },
    emitChange() {
      this.$emit('change', this.tierPriceMode ? this.tierPricesModel : this.unitPricesModel);
    },
    onSubmit(e) {
      this.$emit('submit', e);
    },
    onPriceEnter(priceIndex) {
      console.log('enter', priceIndex);
    },
  },
};
</script>
