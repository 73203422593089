var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"flex items-center px-3 border rounded-lg transition duration-150\n    animate__animated bg-white",class:{
      'border-dashed border-2': _vm.addNew,
      'focus-within:ring-2 focus-within:ring-opacity-75':
        _vm.addNew || _vm.editable,
      'focus-within:ring-primary': !_vm.existsError && (_vm.addNew || _vm.editable),
      'focus-within:border-red-500 focus-within:ring-red-500 animate__headShake':
        _vm.existsError,
      'animate__flash border-primary': _vm.highlight,
      'shadow-sm': !_vm.addNew,
      'group': _vm.editable,
      'border-red-600': _vm.validationError,
    }},[_c('CIcon',{staticClass:"transition duration-150",class:{
        'text-red-600': _vm.negative || _vm.existsError,
        'text-green-600': !_vm.negative && !_vm.existsError,
      },attrs:{"name":_vm.icon,"size":"16"}}),(!_vm.editable && !_vm.addNew)?_c('div',{staticClass:"ml-2 py-2"},[_c('CTypo',{attrs:{"tstyle":"body2"}},[_vm._v(_vm._s(_vm.value))])],1):_c('div',{staticClass:"ml-2 flex flex-grow items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valueModel),expression:"valueModel"}],ref:"input",staticClass:"focus:outline-none flex-grow md:text-sm font-medium leading-5 py-2\n        transition duration-150",class:{
          'italic': !_vm.valueModel,
          'text-red-600': _vm.existsError,
        },attrs:{"id":_vm.inputId,"placeholder":_vm.inputPlaceholder,"required":!_vm.addNew,"novalidate":""},domProps:{"value":(_vm.valueModel)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.valueModel=$event.target.value},_vm.onInput],"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"invalid":function($event){$event.preventDefault();return _vm.setValidationMessage.apply(null, arguments)}}}),(_vm.editable)?[_c('button',{ref:"button",staticClass:"focus:outline-none inline-flex p-1 -mr-2 filter\n          grayscale hover:grayscale-0 touch:grayscale-0\n          text-gray-600 transition duration-150\n          focus:ring-primary-2",class:{
            'opacity-20 group-hover:opacity-100 touch:opacity-100':
              !_vm.addNew && _vm.valueModel === _vm.value,
          },attrs:{"type":"button"},on:{"click":function($event){_vm.valueModel === _vm.value ? _vm.onDelete() : _vm.reset()}}},[_c('CIcon',{attrs:{"name":_vm.valueModel === _vm.value ? 'backspace' : 'reply',"outline":"","size":"24"}})],1)]:_vm._e()],2)],1),(_vm.currentMessage)?_c('div',{staticClass:"mt-1 ml-1 text-right",class:{
      'text-red-500': _vm.validationError,
      'text-gray-500': !_vm.validationError,
    }},[_c('CTypo',{attrs:{"tstyle":"footnote2"}},[_vm._v(_vm._s(_vm.currentMessage))])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }