<template>
  <div>
    <AcceptanceListItem
      v-for="(item, index) in items"
      :key="`item${index}`"
      :value="item"
      :editable="editable"
      :negative="negative"
      :highlight="highlightItem === index"
      @submit="(e) => onItemChangeSubmit(e, index)"
      @delete="onItemDelete(index)"
    />
    <AcceptanceListItem
      v-if="addNew"
      v-model="newItem"
      :negative="negative"
      add-new
      @submit="(e) => onItemChangeSubmit(e)"
    />
  </div>
</template>

<script>
import AcceptanceListItem from './AcceptanceListItem.vue';

export default {
  components: {
    AcceptanceListItem,
  },

  model: {
    prop: 'items',
    event: 'change',
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    newItem: null,
    highlightItem: -1,
  }),

  methods: {
    onItemChange(value, index, successFunc) {
      if (this.editable && value) {
        const { items } = this;
        items[index] = value;
        this.$emit('change', items);
        successFunc();
      }
    },
    onItemChangeSubmit({ value, exists, success }, index) {
      this.highlightItem = -1;
      if (this.highlightTimeout) clearTimeout(this.highlightTimeout);
      const findIndex = this.items.findIndex((val) => val === value);
      if (findIndex > -1) {
        exists();
        this.highlightItem = findIndex;
        this.highlightTimeout = setTimeout(() => {
          this.highlightItem = -1;
        }, 1500);
      } else if (index !== undefined) {
        this.onItemChange(value, index, success);
      } else {
        const { items } = this;
        items.push(value);
        this.$emit('change', items);
        success();
      }
    },
    onItemDelete(index) {
      const { items } = this;
      items.splice(index, 1);
      this.$emit('change', items);
    },
  },
};
</script>
