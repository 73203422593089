import type { TMerchantWaste } from '@contimo/api/src/api/merchantWastes';
import type { TPlatformWaste } from '@contimo/api/src/api/platformWastes';
import Vue from 'vue';
import { Module } from 'vuex';
import germanDecimalParser, { germanDecimalToNumber } from '@/utils/germanDecimalParser';
import type { TRootStore } from '..';
import { CREATE_MERCHANT_WASTE, UPDATE_MERCHANT_WASTE } from '../actionTypes';
import { ALL_PLATFORM_WASTES } from '../gettersTypes';

export type TMerchantWastePrice = {
  pricePerCubicMeter: number | null;
  minSize: number;
};

export interface IMerchantWasteFormStoreState {
  updateId: number | null;
  step: number;
  form: {
    platformWasteId: number | 'CUSTOM' | null;
    customName: string | null;
    minSize: number | string | null;
    maxSize: number | string | null;
    minSizeRolloff: number | string | null;
    maxSizeRolloff: number | string | null;
    accepted: string[];
    notAccepted: string[];
    prices: TMerchantWastePrice[];
    basePrice: number | null;
    notes: Record<string, any>;
  };
  placeholderImage: File | null;
  currentPlaceholderImage: string | null;
  updatePlatformWaste: TPlatformWaste | null;
  updatePlaceholderImage: boolean;
  useOwnPlaceholder: boolean;
  priceModel: 'tier' | 'unit';
  sizeMode: 'uniform' | 'byContainerType';
}

type TMerchantWasteFormStore = Module<IMerchantWasteFormStoreState, TRootStore>;

const merchantWasteFormStore: TMerchantWasteFormStore = {
  namespaced: true,

  state: () => ({
    updateId: null,
    step: 0,
    form: {
      platformWasteId: null,
      customName: null,
      minSize: null,
      maxSize: null,
      minSizeRolloff: null,
      maxSizeRolloff: null,
      accepted: [],
      notAccepted: [],
      prices: [{ pricePerCubicMeter: null, minSize: 0 }],
      basePrice: 0,
      notes: { type: 'doc', content: [] },
    },
    currentPlaceholderImage: null,
    placeholderImage: null,
    updatePlatformWaste: null,
    updatePlaceholderImage: false,
    useOwnPlaceholder: false,
    priceModel: 'unit',
    sizeMode: 'uniform',
  }),

  getters: {
    updateMode: (state) => Boolean(state.updateId),
    tierPriceMode: (state) => state.priceModel === 'tier',
    customMode: (state) => state.form.platformWasteId === 'CUSTOM',
    currentTierIndex: (state) => state.form.prices.length - 1,
    unitPriceModel: (state) => state.form.prices[0],
    tierPricesModel: (state) => state.form.prices,
    wasteMaxSize: (state) => state.form.maxSize || 0,
    selectedPlatformWaste: (state, getters, rootState, rootGetters) => {
      return rootGetters[ALL_PLATFORM_WASTES].find(
        (pw: TPlatformWaste) => pw.id === state.form.platformWasteId,
      );
    },
    submitLoading: (state, getters, rootState) => {
      if (getters.updateMode) {
        return rootState.merchantWastes.updateLoading;
      }
      return rootState.merchantWastes.createLoading;
    },
    basePriceModel: (state) => state.form.basePrice,
    sizeMode: (state) => state.sizeMode,
  },

  mutations: {
    changePriceModel(state, newPriceModel: 'tier' | 'unit') {
      state.priceModel = newPriceModel;
    },
    changeBasePrice(state, newBasePrice: number) {
      state.form.basePrice = newBasePrice;
    },
    addTierPrice(state) {
      state.form.prices.push({ pricePerCubicMeter: null, minSize: 0 });
    },
    removeTierPrice(state, index) {
      state.form.prices.splice(index, 1);
    },
    changeTierPrice(state, { index, data }) {
      Vue.set(state.form.prices, index, data);
    },
    setUpdateData(state, data: TMerchantWaste) {
      state.updateId = data.id;
      let { customName } = data;
      if (!customName && data.platformWasteId && data.platformWaste) {
        customName = data.platformWaste.name;
      }
      state.form = {
        platformWasteId: data.platformWasteId,
        customName,
        minSize: germanDecimalParser(data.minSize || 0, true),
        maxSize: germanDecimalParser(data.maxSize || 0, true),
        minSizeRolloff: data.minSizeRolloff
          ? germanDecimalParser(data.minSizeRolloff || 0, true)
          : null,
        maxSizeRolloff: data.maxSizeRolloff
          ? germanDecimalParser(data.maxSizeRolloff || 0, true)
          : null,
        accepted: data.accepted?.slice() || [],
        notAccepted: data.notAccepted?.slice() || [],
        prices: data.prices.slice().sort((a, b) => a.minSize - b.minSize),
        basePrice: data.basePrice,
        notes: data.notes,
      };
      state.updatePlatformWaste = data.platformWaste || null;
      state.currentPlaceholderImage = data.image || data.platformWaste?.image || '';
      state.priceModel = data.prices.length > 1 ? 'tier' : 'unit';
      state.sizeMode = data.minSizeRolloff || data.maxSizeRolloff ? 'byContainerType' : 'uniform';
    },
    resetState(state) {
      state.updateId = null;
      state.form = {
        platformWasteId: null,
        customName: null,
        minSize: null,
        maxSize: null,
        minSizeRolloff: null,
        maxSizeRolloff: null,
        accepted: [],
        notAccepted: [],
        prices: [{ pricePerCubicMeter: null, minSize: 0 }],
        basePrice: 0,
        notes: { type: 'doc', content: [] },
      };
      state.updatePlatformWaste = null;
      state.priceModel = 'unit';
      state.placeholderImage = null;
      state.currentPlaceholderImage = null;
      state.updatePlaceholderImage = false;
      state.sizeMode = 'uniform';
    },
    setFormValue(state, value) {
      state.form = {
        ...state.form,
        ...value,
      };
    },
    setStep(state, step: number) {
      state.step = step;
    },
    setPlaceholderImage(state, file: File | null) {
      state.placeholderImage = file;
    },
    changeSizeMode(state, newSizeMode: 'uniform' | 'byContainerType') {
      state.sizeMode = newSizeMode;
    },
  },

  actions: {
    async submit({ dispatch, getters, state }) {
      let customNameHelper: string | null = null;
      const {
        platformWasteId,
        customName,
        minSize,
        maxSize,
        accepted,
        notAccepted,
        basePrice,
        notes,
      } = state.form;
      let { minSizeRolloff, maxSizeRolloff } = state.form;

      customNameHelper = customName;
      const platformWasteIdHelper = platformWasteId === 'CUSTOM' ? null : platformWasteId;
      let prices: TMerchantWastePrice[] = [];
      if (getters.tierPriceMode) {
        prices = getters.tierPricesModel.map((price: TMerchantWastePrice) => ({
          pricePerCubicMeter: price.pricePerCubicMeter?.toString() || 0,
          minSize: germanDecimalToNumber(price.minSize),
        }));
      } else {
        prices = [
          {
            pricePerCubicMeter: getters.unitPriceModel.pricePerCubicMeter || 0,
            minSize: germanDecimalToNumber(getters.unitPriceModel.minSize),
          },
        ];
      }

      if (state.sizeMode === 'uniform') {
        minSizeRolloff = null;
        maxSizeRolloff = null;
      }

      const submitForm = {
        platformWasteId: platformWasteIdHelper,
        customName: customNameHelper,
        prices,
        minSize: germanDecimalToNumber(minSize || 1),
        maxSize: germanDecimalToNumber(maxSize || 99),
        minSizeRolloff: minSizeRolloff ? germanDecimalToNumber(minSizeRolloff) : null,
        maxSizeRolloff: maxSizeRolloff ? germanDecimalToNumber(maxSizeRolloff) : null,
        accepted,
        notAccepted,
        placeholder: state.placeholderImage || null,
        basePrice: basePrice?.toString() || 0,
        notes,
      };
      if (!getters.updateMode) {
        await dispatch('submitCreate', submitForm, { root: false });
      } else {
        await dispatch('submitUpdate', submitForm, { root: false });
      }
    },
    async submitUpdate({ dispatch, commit, state }, submitForm) {
      await dispatch(
        UPDATE_MERCHANT_WASTE,
        { id: state.updateId, body: submitForm },
        { root: true },
      );
      setTimeout(() => {
        commit('setStep', 0);
        commit('resetState');
      }, 1000);
      return true;
    },
    async submitCreate({ dispatch, commit }, submitForm) {
      await dispatch(CREATE_MERCHANT_WASTE, submitForm, { root: true });
      setTimeout(() => {
        commit('setStep', 0);
        commit('resetState');
      }, 1000);
      return true;
    },
  },
};

export default merchantWasteFormStore;
