<template>
  <CModal
    ref="modal"
    :show="show"
    :submit-form="submitForm"
    :variant="modes.updateMode && step === 0 ? 'formsheet' : 'pagesheet'"
    :no-cancel="step > 0"
    :custom-footer="step > 0"
    :submit-loading="submitLoading"
    :close-button="modes.updateMode || step === 0"
    @toggle="onToggle"
  >
    <template v-slot:header>
      <template v-if="modes.updateMode && step === 0">
        {{ $t('merchantWastes.update', { name }) }}
      </template>
      <template v-else-if="step === 0">
        {{ $t('merchantWastes.add') }}
      </template>
      <template v-else-if="step === 1">
        {{ $t('merchantWastes.allowed') }}
      </template>
      <template v-else-if="step === 2">
        {{ $t('merchantWastes.priceModelFor', { name }) }}
      </template>
    </template>
    <form v-if="step === 0" id="create-update-merchant-wastes-form" @submit.prevent="onSubmit">
      <template v-if="!modes.updateMode">
        <CTypo
          tstyle="caption1"
          class="text-gray-700 mb-3"
        >
          {{ $t('merchantWastes.selectPlatformWaste') }}
        </CTypo>
        <div class="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <CSelectBox
            v-for="waste in platformWastes"
            :key="waste.id"
            v-model="form.platformWasteId"
            :native-value="waste.id"
            label-class="flex items-center w-full"
            @change="onChangeWaste(waste)"
          >
            <CSvgImage
              :src="waste.image"
              class="inline-flex w-16 h-16 mr-3 rounded"
              svg-class="w-16 h-16"
            />
            <CTypo tstyle="body" class="text-center font-medium flex-grow">
              {{ $t(waste.translationSlug) }}
            </CTypo>
          </CSelectBox>
          <CSelectBox
            v-model="form.platformWasteId"
            native-value="CUSTOM"
            label-class="flex items-center w-full py-3"
            v-slot="{ checked }"
            @change="onChangeWaste({ id: 'CUSTOM', name: null })"
          >
            <div
              class="inline-flex w-8 mr-3 rounded justify-center"
              :class="{
                'text-gray-500': !checked,
              }"
            >
              <CIcon name="plus-circle-outline" size="32" />
            </div>
            <CTypo tstyle="body" class="text-center font-medium flex-grow">
              {{ $t('merchantWastes.createOwnWaste') }}
            </CTypo>
          </CSelectBox>
        </div>
      </template>
      <div
        class="modal-data-form grid grid-cols-1"
        :class="{
          'gap-8 md:grid-cols-2 lg:grid-cols-3': !modes.updateMode,
        }"
      >
        <div class="md:col-span-1">
          <CDivider v-if="!modes.updateMode">{{ $t('details') }}</CDivider>
          <CTextField
            v-model="form.customName"
            :label="$t('name')"
            :required="modes.customMode"
          />
        </div>
        <div class="md:col-span-1">
          <CDivider>{{ $tc('size', 2) }}</CDivider>
          <CSegmentControl v-model="sizeMode">
            <CSegmentControlItem value="uniform" :label="$t('waste.uniform')" />
            <CSegmentControlItem value="byContainerType" :label="$t('waste.byTypeOfContainer')" />
          </CSegmentControl>
          <div v-if="sizeMode === 'uniform'" class="mt-3">
            <CAlert variant="info">
              {{ $t('waste.uniformWarning') }}
            </CAlert>
            <CTextField
              v-model="form.minSize"
              :label="$t('minContainerSize')"
              :mask="containerSizeMask"
              required
            />
            <CTextField
              v-model="form.maxSize"
              :label="$t('maxContainerSize')"
              :mask="containerSizeMask"
              required
            />
          </div>
          <div v-else class="mt-3">
            <CTextField
              v-model="form.minSize"
              :label="$t('minContainerSizeDropoff')"
              :mask="containerSizeMask"
              required
            />
            <CTextField
              v-model="form.maxSize"
              :label="$t('maxContainerSizeDropoff')"
              :mask="containerSizeMask"
              required
            />
            <CTextField
              v-model="form.minSizeRolloff"
              :label="$t('minContainerSizeRolloff')"
              :mask="containerSizeMask"
              required
            />
            <CTextField
              v-model="form.maxSizeRolloff"
              :label="$t('maxContainerSizeRolloff')"
              :mask="containerSizeMask"
              required
            />
          </div>
        </div>
        <div
          class="md:col-span-1"
          :class="{
            'lg:col-span-1': !modes.updateMode,
          }"
        >
          <CDivider>{{ $t('placeholderImage') }}</CDivider>
          <template
            v-if="(!modes.updateMode && (useOwnPlaceholder || modes.customMode))
              || updatePlaceholderImage"
          >
            <CImageUpload
              v-model="placeholderImage"
              :label="uploadLabel"
              :required="modes.customMode"
            />
            <CButton v-if="modes.updateMode" class="mt-4" @click="updatePlaceholderImage = false">
              {{ $t('merchantWastes.useOldPlaceholder') }}
            </CButton>
            <CButton
              v-else-if="!modes.customMode && useOwnPlaceholder"
              @click="useOwnPlaceholder = false"
            >
              {{ $t('merchantWastes.useDefaultPlaceholder') }}
            </CButton>
          </template>
          <div v-else-if="modes.updateMode" class="grid grid-cols-2 gap-4 md:gap-6">
            <img class="w-[300px]" :src="currentImage">
            <div>
              <CButton @click="updatePlaceholderImage = true">
                {{ $t('merchantWastes.changePlaceholder') }}
              </CButton>
            </div>
          </div>
          <div v-else-if="!useOwnPlaceholder && selectedPlatformWaste">
            <img :src="selectedPlatformWaste.image">
            <div class="mt-4">
              <CButton @click="useOwnPlaceholder = true">
                {{ $t('merchantWastes.useOwnPlaceholder') }}
              </CButton>
            </div>
          </div>
        </div>
      </div>
    </form>
    <form
      v-else-if="step === 1"
      id="create-update-merchant-wastes-acceptance"
      @submit.prevent="onSubmit"
    >
      <div class="grid md:grid-cols-2 gap-4 lg:gap-8">
        <CEditor ref="notesEditor" :value="notesValue" class="col-span-2">
          <template v-slot:header>{{ $t('merchantWastes.notes') }}</template>
        </CEditor>
        <div class="-mt-4">
          <CDivider>{{ $t('merchantWastes.accepted') }}</CDivider>
          <AcceptanceList
            v-model="accepted"
            add-new
            editable
          />
        </div>
        <div class="-mt-4">
          <CDivider>{{ $t('merchantWastes.notAccepted') }}</CDivider>
          <AcceptanceList
            v-model="notAccepted"
            add-new
            editable
            negative
          />
        </div>
      </div>
    </form>
    <div v-else class="grid grid-cols-1 lg:grid-cols-3 gap-4 lg:gap-8">
      <div class="lg:col-span-2">
        <PriceModelForm id="create-update-merchant-wastes-priceform" @submit="onSubmit" />
      </div>
      <div class="lg:col-span-1">
        <PriceCalculator
          :base-price="basePrice"
          :prices="priceModel === 'unit' ? unitPrice : tierPrices"
          :min-container-size="containerSize.min"
          :max-container-size="containerSize.max"
        />
      </div>
    </div>
    <CValidationFailure :error-fields-response="errorFieldsResponse" />
    <template v-slot:footer>
      <CButton tabindex="-1" @click="step -= 1">{{ $t('back') }}</CButton>
    </template>
    <template v-if="step === 2" v-slot:submit>
      {{ $t('save') }}
    </template>
  </CModal>
</template>

<script>
import { mapGetters } from 'vuex';
import { ALL_MERCHANT_WASTES, ALL_PLATFORM_WASTES } from '@/store/gettersTypes';
import { MASK_CONTAINER_SIZE } from '@/constants/ui';
import germanDecimalParser from '@/utils/germanDecimalParser';
import PriceModelForm from './PriceModelForm.vue';
import PriceCalculator from './PriceCalculator.vue';
import AcceptanceList from './AcceptanceList.vue';

export default {
  components: {
    PriceModelForm,
    PriceCalculator,
    AcceptanceList,
  },

  model: {
    prop: 'show',
    event: 'toggle',
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      errorFieldsResponse: null,
    };
  },

  computed: {
    ...mapGetters([ALL_PLATFORM_WASTES, ALL_MERCHANT_WASTES]),
    submitForm() {
      if (this.step === 0) return 'create-update-merchant-wastes-form';
      if (this.step === 1) return 'create-update-merchant-wastes-acceptance';
      return 'create-update-merchant-wastes-priceform';
    },
    submitLoading() {
      return this.$store.getters['merchantWasteForm/submitLoading'];
    },
    modes() {
      return {
        updateMode: this.$store.getters['merchantWasteForm/updateMode'],
        customMode: this.$store.getters['merchantWasteForm/customMode'],
      };
    },
    selectedPlatformWaste() {
      return this.$store.getters['merchantWasteForm/selectedPlatformWaste'];
    },
    platformWastes() {
      const existing = this.allMerchantWastes.map((mw) => mw.platformWaste?.id || null);
      return this.allPlatformWastes.filter((pw) => !existing.includes(pw.id));
    },
    priceModel() {
      return this.$store.state.merchantWasteForm.priceModel;
    },
    unitPrice() {
      return [this.$store.getters['merchantWasteForm/unitPriceModel']];
    },
    tierPrices() {
      return this.$store.getters['merchantWasteForm/tierPricesModel'];
    },
    basePrice() {
      return this.$store.getters['merchantWasteForm/basePriceModel'];
    },
    notesValue() {
      return this.$store.state.merchantWasteForm.form.notes;
    },
    step: {
      get() {
        return this.$store.state.merchantWasteForm.step;
      },
      set(value) {
        this.$store.commit('merchantWasteForm/setStep', value);
      },
    },
    accepted: {
      get() {
        return this.$store.state.merchantWasteForm.form.accepted.slice();
      },
      set(items) {
        this.$store.state.merchantWasteForm.form.accepted = items;
      },
    },
    notAccepted: {
      get() {
        return this.$store.state.merchantWasteForm.form.notAccepted.slice();
      },
      set(items) {
        this.$store.state.merchantWasteForm.form.notAccepted = items;
      },
    },
    placeholderImage: {
      get() {
        return this.$store.state.merchantWasteForm.placeholderImage;
      },
      set(file) {
        this.$store.commit('merchantWasteForm/setPlaceholderImage', file);
      },
    },
    currentImage() {
      return this.$store.state.merchantWasteForm.currentPlaceholderImage;
    },
    updatePlaceholderImage: {
      get() {
        return this.$store.state.merchantWasteForm.updatePlaceholderImage;
      },
      set(value) {
        this.$store.state.merchantWasteForm.updatePlaceholderImage = value;
      },
    },
    form() {
      return this.$store.state.merchantWasteForm.form;
    },
    name() {
      const { customName } = this.$store.state.merchantWasteForm.form;
      if (this.$store.state.merchantWasteForm.form.platformWasteId) {
        return customName || this.$store.state.merchantWasteForm.updatePlatformWaste.name;
      }
      return customName;
    },
    containerSizeMask() {
      return MASK_CONTAINER_SIZE;
    },
    containerSize() {
      let max = this.form.maxSize ? Number(germanDecimalParser(this.form.maxSize).toFixed(0)) : 0;
      let min = this.form.minSize ? Number(germanDecimalParser(this.form.minSize).toFixed(0)) : 0;
      if (this.sizeMode !== 'uniform') {
        if (this.form.maxSizeRolloff > max) {
          max = this.form.maxSizeRolloff
            ? Number(germanDecimalParser(this.form.maxSizeRolloff).toFixed(0))
            : 0;
        }
        if (this.form.minSizeRolloff < min) {
          min = this.form.minSizeRolloff
            ? Number(germanDecimalParser(this.form.minSizeRolloff).toFixed(0))
            : 0;
        }
      }

      return {
        max,
        min,
      };
    },
    uploadLabel() {
      if (this.modes.updateMode) {
        return this.$t('merchantWastes.choosePictureForNewPlaceholder');
      }
      return this.$t('merchantWastes.choosePictureForPlaceholder');
    },
    useOwnPlaceholder: {
      get() {
        if (this.modes.customMode) return true;
        return this.$store.state.merchantWasteForm.useOwnPlaceholder;
      },
      set(value) {
        this.$store.state.merchantWasteForm.useOwnPlaceholder = value;
      },
    },
    sizeMode: {
      get() {
        return this.$store.state.merchantWasteForm.sizeMode;
      },
      set(val) {
        this.$store.commit('merchantWasteForm/changeSizeMode', val);
      },
    },
  },

  watch: {
    '$store.state.merchantWasteForm': {
      handler() {
        this.errorFieldsResponse = null;
      },
      deep: true,
    },
  },

  methods: {
    onToggle(show) {
      this.$emit('toggle', show);
      if (!show) {
        this.$emit('close');
        this.step = 0;
      }
    },
    async onSubmit() {
      if (this.step === 0) {
        this.step = 1;
      } else if (this.step === 1) {
        this.form.notes = this.$refs.notesEditor.getJSON();
        this.step = 2;
      } else {
        try {
          await this.$store.dispatch('merchantWasteForm/submit');
        } catch (e) {
          if (e.response?.data?.code === 'E_VALIDATION_FAILURE') {
            this.errorFieldsResponse = e.response;
            return;
          }
          throw e;
        }
        this.onToggle(false);
      }
    },
    onChangeWaste(waste) {
      this.form.customName = waste.name;
      if (waste.id !== 'CUSTOM') {
        this.form.minSize = waste.defaultMinSize;
        this.form.maxSize = waste.defaultMaxSize;
        this.form.minSizeRolloff = null;
        this.form.maxSizeRolloff = null;
        this.form.accepted = waste.defaultAccepted;
        this.form.notAccepted = waste.defaultNotAccepted;
      } else {
        this.form.minSize = null;
        this.form.maxSize = null;
        this.form.minSizeRolloff = null;
        this.form.maxSizeRolloff = null;
        this.form.accepted = [];
        this.form.notAccepted = [];
      }
      this.$nextTick(() => {
        const { id, scrollTo } = this.$refs.modal;
        const scrollToEl = this.$el.querySelector(`#${id} .modal-data-form`);
        scrollTo(scrollToEl);
      });
    },
  },
};
</script>
