<template>
  <div class="mb-2">
    <div
      class="flex items-center px-3 border rounded-lg transition duration-150
      animate__animated bg-white"
      :class="{
        'border-dashed border-2': addNew,
        'focus-within:ring-2 focus-within:ring-opacity-75':
          addNew || editable,
        'focus-within:ring-primary': !existsError && (addNew || editable),
        'focus-within:border-red-500 focus-within:ring-red-500 animate__headShake':
          existsError,
        'animate__flash border-primary': highlight,
        'shadow-sm': !addNew,
        'group': editable,
        'border-red-600': validationError,
      }"
    >
      <CIcon
        :name="icon"
        size="16"
        class="transition duration-150"
        :class="{
          'text-red-600': negative || existsError,
          'text-green-600': !negative && !existsError,
        }"
      />
      <div v-if="!editable && !addNew" class="ml-2 py-2">
        <CTypo tstyle="body2">{{ value }}</CTypo>
      </div>
      <div v-else class="ml-2 flex flex-grow items-center">
        <input
          v-model="valueModel"
          ref="input"
          :id="inputId"
          class="focus:outline-none flex-grow md:text-sm font-medium leading-5 py-2
          transition duration-150"
          :class="{
            'italic': !valueModel,
            'text-red-600': existsError,
          }"
          :placeholder="inputPlaceholder"
          :required="!addNew"
          novalidate
          @input="onInput"
          @keydown.enter.prevent="onSubmit"
          @invalid.prevent="setValidationMessage"
        >
        <template v-if="editable">
          <button
            ref="button"
            type="button"
            class="focus:outline-none inline-flex p-1 -mr-2 filter
            grayscale hover:grayscale-0 touch:grayscale-0
            text-gray-600 transition duration-150
            focus:ring-primary-2"
            :class="{
              'opacity-20 group-hover:opacity-100 touch:opacity-100':
                !addNew && valueModel === value,
            }"
            @click="valueModel === value ? onDelete() : reset()"
          >
            <CIcon :name="valueModel === value ? 'backspace' : 'reply'" outline size="24" />
          </button>
        </template>
      </div>
    </div>
    <div
      v-if="currentMessage"
      class="mt-1 ml-1 text-right"
      :class="{
        'text-red-500': validationError,
        'text-gray-500': !validationError,
      }"
    >
      <CTypo tstyle="footnote2">{{ currentMessage }}</CTypo>
    </div>
  </div>
</template>

<script>
import objectid from '@/utils/objectid';

export default {
  props: {
    value: {
      type: String,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
    addNew: {
      type: Boolean,
      default: false,
    },
    highlight: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: objectid('acceptanceitem'),
    },
  },

  data: () => ({
    existsError: false,
    valueModel: null,
    validationError: false,
  }),

  computed: {
    icon() {
      return this.negative || this.existsError ? 'x-circle' : 'check-circle';
    },
    inputPlaceholder() {
      if (this.addNew) return this.$t('createAnotherItem');
      return null;
    },
    currentMessage() {
      if (this.validationError) {
        return this.validationError;
      }
      if (
        this.valueModel &&
        this.valueModel.length > 0 &&
        !this.addNew &&
        this.valueModel !== this.value
      ) {
        return this.$t('helpMessages.clickEnterToSave');
      }
      if (this.valueModel && this.valueModel.length > 0 && this.addNew) {
        return this.$t('helpMessages.clickEnterToSave');
      }
      return null;
    },
    inputEl() {
      return this.$el.querySelector(`#${this.inputId}`);
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        this.valueModel = newVal;
      },
    },
  },

  methods: {
    onSubmit(e) {
      if ((this.addNew && this.valueModel) || (this.editable && this.valueModel !== this.value)) {
        this.$emit('submit', {
          value: e.target.value,
          exists: () => {
            this.existsError = true;
            setTimeout(() => {
              this.existsError = false;
            }, 500);
          },
          success: () => {
            this.inputEl.blur();
            if (this.addNew) this.valueModel = null;
          },
        });
      }
    },
    onInput(e) {
      this.$emit('input', e.target.value);
      if (this.validationError) {
        this.checkValidity();
      }
    },
    onDelete() {
      this.$emit('delete');
    },
    reset() {
      this.inputEl.blur();
      this.$refs.button.blur();
      this.valueModel = this.value;
      this.$nextTick(() => {
        if (this.validationError) {
          this.checkValidity();
        }
      });
    },
    checkValidity() {
      if (this.editable) {
        const fieldValidity = this.inputEl.checkValidity();
        if (!fieldValidity) {
          this.setValidationMessage();
        } else {
          this.validationError = false;
        }
      }
    },
    setValidationMessage() {
      const { validationMessage } = this.inputEl;
      this.validationError = validationMessage;
    },
  },
};
</script>
