<template>
  <div>
    <CInfoCard>
      <CTypo tstyle="caption1" class="text-gray-700">
        {{ $t('merchantWastes.priceCalculator') }}
      </CTypo>
      <CTypo v-if="previewPrice" tstyle="title2" class="text-gray-700">
        {{ $n(previewPrice, 'currency') }}
      </CTypo>
      <CTypo v-else tstyle="body" class="text-gray-700 mt-1">
        {{ $t('merchantWastes.addPriceToPreview') }}
      </CTypo>
      <template v-slot:description>
        <div v-if="previewPrice" class="mb-2">
          {{ $t('merchantWastes.previewPricePlusAdditionalCosts') }}
        </div>
        <CSlider
          v-model="containerSize"
          :label="$t('containerSize')"
          :step="1"
          :min="minContainerSize"
          :max="maxContainerSize"
          indicator
        />
        <CTextField
          v-model="tax"
          :label="$t('taxRate')"
          type="number"
          inputmode="numeric"
        />
      </template>
    </CInfoCard>
  </div>
</template>

<script>
import { decimalPriceToInteger } from '@/utils/decimalToInteger';

export default {
  props: {
    basePrice: {
      type: [String, Number],
    },
    prices: {
      type: Array,
      required: true,
    },
    minContainerSize: {
      type: Number,
      default: 1,
    },
    maxContainerSize: {
      type: Number,
      required: true,
    },
  },

  data: () => ({
    tax: 19,
    containerSize: 1,
  }),

  computed: {
    pricePerCubicMeter() {
      return this.prices
        .slice()
        .filter((price) => price.minSize <= this.containerSize)
        .sort((a, b) => b.minSize - a.minSize)[0].pricePerCubicMeter;
    },
    previewPrice() {
      if (this.pricePerCubicMeter) {
        const price = decimalPriceToInteger(this.pricePerCubicMeter);
        const decimal = price / 100;

        return (decimal * this.containerSize + decimal) * (1 + Number(this.tax) / 100);
      }
      return null;
    },
  },

  created() {
    this.containerSize = Number((this.maxContainerSize / 2).toFixed(0));
  },
};
</script>
