<template>
  <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
    <GridSelectorItem
      v-for="waste in allMerchantWastes"
      :key="waste.id"
      :data="waste"
      @update="openCreateUpdateModal(waste)"
      @delete="openDeleteDialog(waste)"
    />
    <button
      v-if="showAddButton"
      class="border bg-white flex flex-col items-center justify-center
      rounded-lg shadow-sm p-4 text-gray-700 focus:outline-none focus:ring-primary-3
      hover:shadow-lg transition duration-150"
      @click="openCreateUpdateModal(null)"
    >
      <CIcon name="plus-circle" size="24" />
      <CTypo el="div" tstyle="subheadline" class="mt-4">
        {{ $t('merchantWastes.add') }}
      </CTypo>
    </button>
    <Portal to="overlays">
      <CModal
        v-model="deleteDialog"
        variant="dialog"
        submit-variant="danger"
        header-icon="exclamation-circle"
        :submit-countdown="1"
        :submit-loading="deleteLoading"
        @submit="onDelete"
      >
        <template v-slot:header>
          {{ $t('actionQuestion.confirmDelete') }}
        </template>
        {{ $t('actionQuestions.merchantWaste.reallyWantToDelete', { name: deleteObjName }) }}
        <template v-slot:submit>{{ $t('delete') }}</template>
      </CModal>
    </Portal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DELETE_MERCHANT_WASTE, GET_MERCHANT_WASTES } from '@/store/actionTypes';
import { ALL_MERCHANT_WASTES } from '@/store/gettersTypes';
import GridSelectorItem from './GridSelectorItem.vue';

export default {
  components: {
    GridSelectorItem,
  },

  props: {
    showAddButton: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    deleteDialog: false,
    deleteObj: null,
  }),

  computed: {
    ...mapGetters([ALL_MERCHANT_WASTES]),
    deleteLoading() {
      return this.$store.state.merchantWastes.updateLoading;
    },
    deleteObjName() {
      if (this.deleteObj) {
        return this.deleteObj.customName || this.deleteObj.platformWaste.name;
      }
      return null;
    },
  },

  created() {
    this.getMerchantWastes({
      limit: 50,
    });
  },

  methods: {
    ...mapActions([GET_MERCHANT_WASTES, DELETE_MERCHANT_WASTE]),
    openCreateUpdateModal(data = null) {
      if (data === null) {
        this.$emit('open-create', null);
      } else {
        this.$emit('open-update', data);
      }
    },
    openDeleteDialog(waste) {
      this.deleteObj = waste;
      this.deleteDialog = true;
    },
    onDelete() {
      this.deleteMerchantWaste(this.deleteObj.id).then(() => {
        this.deleteDialog = false;
      });
    },
  },
};
</script>
