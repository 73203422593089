<template>
  <div
    class="border bg-white flex flex-col items-center justify-center
      rounded-lg shadow-sm p-3 md:p-4 text-gray-700 transition duration-150 select-none"
  >
    <div class="flex justify-center mb-2 min-h-[100px]">
      <CSvgImage :src="image" class="w-[100px] rounded select-none" />
    </div>
    <CTypo tstyle="title3" class="text-center">{{ name }}</CTypo>
    <div class="flex justify-center items-center text-gray-700 mb-2">
      <CTypo tstyle="footnote1">{{ minSize }} m&sup3;</CTypo>
      <span class="inline-flex mx-1 text-xs"> - </span>
      <CTypo tstyle="footnote2">{{ maxSize }} m&sup3;</CTypo>
    </div>
    <div class="flex justify-center">
      <CTypo
        tstyle="footnote1"
        class="bg-primary bg-opacity-20 py-1 px-2 text-primary rounded-full"
      >
        {{ $t('merchantWastes.priceFrom', { price: $n(minPrice, 'currency') }) }}/m&sup3;
      </CTypo>
    </div>
    <div class="inline-flex mt-2">
      <CButton size="small" rounded @click="emitUpdate">{{ $t('update') }}</CButton>
      <CButton
        icon="trash-outline"
        size="small"
        variant="danger"
        inverted
        rounded
        class="ml-1"
        @click="emitDelete"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    createItem: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    name() {
      if (this.data.customName) return this.data.customName;
      return this.$t(this.data.platformWaste.translationSlug);
    },
    minSize() {
      if (this.data.minSize) return this.data.minSize;
      return this.data.platformWaste.defaultMinSize;
    },
    maxSize() {
      if (this.data.maxSize) return this.data.maxSize;
      return this.data.platformWaste.defaultMaxSize;
    },
    image() {
      if (this.data.image) return this.data.image;
      return this.data.platformWaste.image;
    },
    minPrice() {
      const prices = this.data.prices.sort((a, b) => a.pricePerCubicMeter - b.pricePerCubicMeter);
      return prices[0].pricePerCubicMeter + this.data.basePrice;
    },
  },

  methods: {
    emitDelete() {
      this.$emit('delete', this.data.id);
    },
    emitUpdate() {
      this.$emit('update', this.data);
    },
  },
};
</script>
